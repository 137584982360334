import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layout size={20} />,
    navLink: "/",
  },
  //! -----------Home Page---------
  {
    id: "homePage",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/home",
  },

  //!----------------amenities--------

  {
    id: "amenities",
    title: "Amenities",
    type: "collapse",
    icon: <Icon.Star size={15} />,
    children: [
      {
        id: "amenitiesList",
        title: "Amenities List",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/amenities/list",
      },
      {
        id: "propertAmenitiesList",
        title: "Property Amenities",
        type: "item",
        icon: <Icon.Star size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/property-amenities/list",
      },
    ],
  },
  //!----------------partners--------
  {
    id: "partnersList",
    title: "Our Partners",
    type: "item",
    icon: <Icon.Users size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/partners/list",
  },

  {
    id: "blogs",
    title: "Blogs",
    type: "collapse",
    icon: <Icon.AlertTriangle size={15} />,
    children: [
      {
        id: "writersList",
        title: "Writers",
        type: "item",
        icon: <Icon.Users size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/writers/list",
      },
      {
        id: "blogList",
        title: "Blog",
        type: "item",
        icon: <Icon.AlertTriangle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/blog/list",
      },
      {
        id: "commentslist",
        title: "Comments List",
        type: "item",
        icon: <Icon.Users size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/comments",
      },
    ],
  },

  // !---------------- Media--------
  {
    id: "medialist",
    title: "Media",
    type: "item",
    icon: <Icon.Video size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/media/list",
  },

  // !---------------- Media--------
  {
    id: "testimoniallist",
    title: "Testimonials",
    type: "item",
    icon: <Icon.Star size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/testimonial/list",
  },

  // !---------------- Team--------
  {
    id: "teamlist",
    title: "Our Team",
    type: "item",
    icon: <Icon.Users size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/team/list",
  },

  // !---------------- Proud Accomplishment--------
  {
    id: "proudAccomplishment",
    title: "Proud Accomplishment",
    type: "collapse",
    icon: <Icon.Award size={15} />,
    children: [
      {
        id: "proudAccomplishmentlist",
        title: "Locations",
        type: "item",
        icon: <Icon.MapPin size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/proud-accomplishment/list",
      },
      {
        id: "accomplishmentPropertieslist",
        title: "Properties List",
        type: "item",
        icon: <Icon.Award size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/accomplishment-properties/list",
      },
    ],
  },

  // !---------------- Project Highlights--------
  {
    id: "ProjectHighlights",
    title: "Project Highlights",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/project-highlights",
  },

  //!---------------Property Calculator------
  {
    id: "propertycalculator",
    title: "Property Calculator",
    type: "collapse",
    icon: <Icon.Calendar size={15} />,
    children: [
      {
        id: "areas",
        title: "Areas",
        type: "item",
        icon: <Icon.MapPin size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/areas/list",
      },
      {
        id: "PropertyType",
        title: "Property Type",
        type: "item",
        icon: <Icon.Box size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/property-type/list",
      },
      {
        id: "areaPropertyCombination",
        title: "Area Property Combination",
        type: "item",
        icon: <Icon.Calendar size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/property-calculator/list",
      },
    ],
  },

  // !--------------Bookings--------
  {
    id: "bookingslist",
    title: "Bookings",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/bookings/list",
  },

  // !--------------Sales--------
  {
    id: "saleslist",
    title: "Sales",
    type: "item",
    icon: <Icon.DollarSign size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/sales/list",
  },

  // !--------------Bookings--------
  {
    id: "bookingReportlist",
    title: "Booking Report",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/booking-report/list",
  },

  //!--------------Pages-------
  {
    id: "pages",
    title: "Pages",
    type: "item",
    icon: <Icon.Paperclip size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/pages",
  },

  {
    id: "staticPages",
    title: "Static Pages",
    type: "collapse",
    icon: <Icon.Anchor size={15} />,
    children: [
      {
        id: "About",
        title: "About Us",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/about",
      },
      {
        id: "Services",
        title: "Services",
        type: "item",
        icon: <Icon.Package size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/services",
      },
      {
        id: "InvestmentAdvisory",
        title: "Investment Advisory",
        type: "item",
        icon: <Icon.Users size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/investment-advisory",
      },
      {
        id: "HolidayHomes",
        title: "Holiday Homes",
        type: "item",
        icon: <Icon.Home size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/holiday-homes",
      },
      {
        id: "InteriorDesigning",
        title: "Interior Designing",
        type: "item",
        icon: <Icon.PlayCircle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/interior-designing",
      },
      {
        id: "ListYourProperty",
        title: "List Your Property",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/list-your-property",
      },
      {
        id: "contact",
        title: "Contact",
        type: "item",
        icon: <Icon.Phone size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/contact",
      },
      {
        id: "refundPolicy",
        title: "Refund Policy",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/refund-policy",
      },
      {
        id: "privacyPolicy",
        title: "Privacy Policy",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/privacy-policy",
      },
      {
        id: "TermsConditions",
        title: "Terms & Conditions",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/terms-and-conditions",
      },
    ],
  },
  {
    id: "FAQS",
    title: "FAQ's",
    type: "item",
    icon: <Icon.HelpCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/faqs",
  },
  {
    id: "ContactUsFormList",
    title: "Contact List",
    type: "item",
    icon: <Icon.Phone size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/contact-list",
  },
  {
    id: "InteriorQuotationList",
    title: "Interior Quotation List",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/interior-quotation/list",
  },
  {
    id: "ListYourPropertyFormList",
    title: "List Your Property Form List",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/property-list",
  },

  //!---------------Users------
  {
    id: "users",
    title: "Registered Users",
    type: "item",
    icon: <Icon.User size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/users-list",
  },

  // //!---------------Reviews------
  // {
  //   id: "reviews",
  //   title: "Reviews",
  //   type: "item",
  //   icon: <Icon.Star size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/reviews",
  // },
  // //!---------------Header------
  // {
  //   id: "header",
  //   title: "Header",
  //   type: "item",
  //   icon: <Icon.Flag size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/header",
  // },
  // //!---------------Footer------
  // {
  //   id: "footer",
  //   title: "Footer",
  //   type: "item",
  //   icon: <Icon.CheckSquare size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/footer",
  // },
  //!-------------Gallery--------
  {
    id: "gallery",
    title: "Gallery",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/gallery",
  },
  //!------------Static Pages--------
];

export default navigationConfig;
